import './Heading.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';

const GiantsHeading: React.FC = () => {
  const {
    robertOsgood,
    eliotCohen,
    doakBarnett,
    mikeLampton
  } = useStaticQuery<{
    robertOsgood: Core.ImageAttributes;
    eliotCohen: Core.ImageAttributes;
    doakBarnett: Core.ImageAttributes;
    mikeLampton: Core.ImageAttributes;
  }>(graphql`
    {
      robertOsgood: file(
        relativePath: {
          regex: "/issues/winter-2019/features/on-the-shoulders-of-giants/images/"
        }
        name: { eq: "portrait_robert-osgood" }
      ) {
        ...MarginaliaImage
      }
      eliotCohen: file(
        relativePath: {
          regex: "/issues/winter-2019/features/on-the-shoulders-of-giants/images/"
        }
        name: { eq: "portrait_eliot-cohen" }
      ) {
        ...MarginaliaImage
      }
      doakBarnett: file(
        relativePath: {
          regex: "/issues/winter-2019/features/on-the-shoulders-of-giants/images/"
        }
        name: { eq: "portrait_doak-barnett" }
      ) {
        ...MarginaliaImage
      }
      mikeLampton: file(
        relativePath: {
          regex: "/issues/winter-2019/features/on-the-shoulders-of-giants/images/"
        }
        name: { eq: "portrait_mike-lampton" }
      ) {
        ...MarginaliaImage
      }
    }
  `);

  return (
    <Core.Masthead color="light" className="GiantsHeading">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Feature</Core.DepartmentLabel>
        <div className="GiantsHeading-title-one">On the</div>
        <div className="GiantsHeading-title-two">Shoulders of Giants</div>
        <div className="GiantsHeading-portraits">
          <div className="GiantsHeading-portraits-image">
            <Core.FullWidthImage
              image={robertOsgood}
              alt=""
              contentSectionPlacement="marginalia"
            />
          </div>
          <div className="GiantsHeading-portraits-image">
            <Core.FullWidthImage
              image={eliotCohen}
              alt=""
              contentSectionPlacement="marginalia"
            />
          </div>
          <div className="GiantsHeading-portraits-image">
            <Core.FullWidthImage
              image={doakBarnett}
              alt=""
              contentSectionPlacement="marginalia"
            />
          </div>
          <div className="GiantsHeading-portraits-image">
            <Core.FullWidthImage
              image={mikeLampton}
              alt=""
              contentSectionPlacement="marginalia"
            />
          </div>
        </div>
        <div className="GiantsHeading-details">
          <div className="GiantsHeading-details-author">by Douglas Birch</div>
          <div>Photography Howard Korn</div>
        </div>
      </Core.ContentWrapper>
    </Core.Masthead>
  );
};

export default GiantsHeading;
