import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import GiantsHeading from './Heading';

interface Props {
  data: {
    eliotCohen: Core.ImageAttributes;
    robertOsgood: Core.ImageAttributes;
    maraKarlin: Core.ImageAttributes;
    maraKarlinAction: Core.ImageAttributes;
    doakBarnett: Core.ImageAttributes;
    mikeLampton: Core.ImageAttributes;
    andrewMertha: Core.ImageAttributes;
    andrewMerthaStudents: Core.ImageAttributes;
  };
}

const FeatureContent: React.FC<Props> = (props) => {
  const {
    eliotCohen,
    robertOsgood,
    maraKarlin,
    maraKarlinAction,
    doakBarnett,
    mikeLampton,
    andrewMertha,
    andrewMerthaStudents
  } = props.data;

  return (
    <Layout title="On the Shoulders of Giants">
      <GiantsHeading />
      <Core.ContentWrapper>
        <Core.ContentSection>
          <Core.LargeText fontStyle="sans" color="beige">
            During this 75th anniversary year, we celebrate the seminal
            contributions of early SAIS greats. Their intellectual DNA continues
            to run deep in their contemporary counterparts—who have grabbed the
            baton to move their fields forward further still.
          </Core.LargeText>
        </Core.ContentSection>
        <Core.HorizontalRule />
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={robertOsgood}
                alt="Robert Osgood"
                contentSectionPlacement="marginalia"
              />
              <Core.Caption>
                <Core.LeadIn color="beige">Robert E. Osgood</Core.LeadIn>
                <br />
                Osgood, who served as director of the Washington Center of
                Foreign Policy Research (1965–73) and dean of SAIS (1973–79),
                wrote more than a dozen books. Perhaps his best-known work is
                Ideals and Self-Interest in America’s Foreign Relations, about
                the classic dilemma at the center of U.S. foreign relations: the
                tension between the country’s principles and its goals.{' '}
                <em>Photo courtesy of SAIS</em>.
              </Core.Caption>
            </>
          }
          marginaliaStackedBottom
        >
          <Core.Flag>Strategic Studies</Core.Flag>
          <Core.PrimaryHeading>
            Defining the ‘SAIS Approach’
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn color="beige">As American children</Core.LeadIn> were
            being taught to “duck and cover” to protect themselves from nuclear
            blasts in the 1950s, President Dwight D. Eisenhower warned that any
            future war would “astonish ... in the way it occurred and in the way
            it is carried out.”
          </p>
          <p>
            <Core.BoldText>Robert E. Osgood</Core.BoldText> spent much of his
            career trying to ensure that America’s future conflicts didn’t hold
            too many surprises. He was among the founding generation of
            academics who studied how America should wield its enormous military
            and economic might after World War II.
          </p>
          <p>
            The Harvard-trained Osgood served in World War II and then taught at
            the University of Chicago before coming to SAIS in 1961. He would
            spend the next 25 years here as the Christian A. Herter Professor of
            American Foreign Policy, helping define the “SAIS approach,” which
            held that national security analysis should be grounded in rigorous
            theoretical and historical concepts.
          </p>
          <Core.FullWidthImage
            image={eliotCohen}
            alt="Eliot Cohen"
            caption="Eliot Cohen"
          />
        </Core.ContentSection>
        <Core.ContentSection>
          <p>
            Osgood, who served as director of the Washington Center of Foreign
            Policy Research (1965–73) and dean of SAIS (1973–79), wrote more
            than a dozen books. Perhaps his best-known work is{' '}
            <em>Ideals and Self-Interest in America’s Foreign Relations</em>,
            about the classic dilemma at the center of U.S. foreign relations:
            the tension between the country’s principles and its goals.
          </p>
          <p>
            Like SAIS scholars today, Osgood’s influence extended far beyond the
            classroom. He served on the White House National Security Council
            under Henry Kissinger in 1969–70 and advised Ronald Reagan during
            his presidential campaign in 1980. He was also one of five members
            of Secretary of State George Shultz’s Policy Planning Council from
            1983–85, during a period of heightened nuclear tensions as the
            Soviet Union began its spiral toward collapse.
          </p>
          <p>
            He is remembered as a quiet, gracious man who wrote with elegance
            and wit. When he died in 1986 at age 65, his funeral was held at the
            National Cathedral.
          </p>
          <p>
            Picking up Osgood’s mantle to continue advancing the “SAIS approach”
            was Eliot Cohen, who in 1989 joined Secretary of Defense Dick
            Cheney’s policy and planning staff. Early on, Cohen was asked to
            write the first draft of a speech for President George H.W. Bush,
            pushing back against Sen. Sam Nunn, who had said that the new
            administration needed to come to grips with the fact that the Cold
            War was over.
          </p>
          <p>
            “I said to myself, actually, I think Nunn’s right. And of course, I
            couldn’t say that,” Cohen recalls. He wrote the speech but says he
            began to wonder if he was cut out for government service.
          </p>
          <p>
            When he was offered a chair in Strategic Studies at SAIS in 1990, he
            accepted the post. “I knew that was the one job that I really
            wanted,” he says.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <div style={{ marginTop: '2em' }} />
              <Core.Blockquote
                color="beige"
                author="Mara Karlin"
                authorTitle=" ’05, PhD ’12"
              >
                Cohen &lsquo;made us question our assumptions and question our
                beliefs, made us argue for and against one another.&rsquo;
              </Core.Blockquote>
            </>
          }
        >
          <Core.FullWidthImage
            image={maraKarlin}
            alt="Mara Karlin portrait"
            caption="Mara Karlin"
          />
          <p>
            Still, over the years, Cohen, who founded the Philip Merrill Center
            for Strategic Studies in 2004, kept being lured back into
            government: He has taken leaves of absence to work for both the
            Pentagon and the State Department.
          </p>
          <p>
            After the first Iraq war, he directed the Air Force’s Gulf War Air
            Power Survey, which produced a five-volume report in 1993 concluding
            that the war’s combination of stealth, precision weaponry, and
            advanced communications technologies would change the nature of
            combat.
          </p>
          <p>
            Cohen served from 2007–09 as counselor of the Department of
            State—senior foreign policy adviser to Secretary of State
            Condoleezza Rice. During a trip to several Afghan regions, he found
            few grounds for the Bush administration’s belief that America and
            its allies were winning, and helped draft plans for a military surge
            to halt or reverse the Taliban’s gains. He played a key role in
            top-secret deliberations over an American response to the discovery
            that North Korea had built a suspected reactor in eastern Syria. In
            the end, Israel bombed the site in September 2007.
          </p>
          <p>
            Cohen has written half a dozen books but says the one he expects to
            have the most enduring impact is <em>Supreme Command</em>, about how
            four civilian leaders—Lincoln, Clemenceau, Churchill, and
            Ben-Gurion—directed their militaries in wartime. He is now writing a
            book about Shakespeare, who also had a few things to say about how
            leaders wage war.
          </p>
          <p>
            In the meantime, Cohen has become one of the most visible and vocal
            critics of President Trump, drawing 54,000 Twitter followers and
            tens of millions of readers to his column in <em>The Atlantic</em>{' '}
            magazine.
          </p>
          <p>
            While he has worked for Republican administrations and advised Mitt
            Romney during his 2012 presidential bid, Cohen rejects political
            labels. He is, he says, “first and foremost a teacher.”
          </p>
          <p>
            His former SAIS students, he says, have served both in both
            Democratic and Republican administrations, and today form networks
            of specialists throughout the government. “We produce people who go
            out into the world and do the work of the world,” he says. “As they
            will tell you, there is an incredible SAIS ‘mafia’ in strategic
            studies. That’s a very gratifying thing.”
          </p>
          <p>
            <Core.BoldText>Mara Karlin</Core.BoldText>, associate professor of
            the practice of Strategic Studies and acting director of the
            Strategic Studies Program at SAIS, says her first boss in Washington
            told her that if she wanted to pursue a career in the field of
            strategic studies, she should study under Cohen.
          </p>
          <p>
            “And he was right,” she says. Cohen “made us question our
            assumptions and question our beliefs, made us argue for and against
            one another. And that pushed all of us into a rigorous learning
            experience in which you could just not be intellectually lazy.”
          </p>
          <p>
            While still working on her master’s degree at SAIS, Karlin landed a
            policy job at the Pentagon’s Office of the Under Secretary of
            Defense for Policy in 2004 and eventually became head of policy for
            the Levant. In that position, she worked on aid programs for Lebanon
            “and tried to figure out how to push back on places like Syria and
            Iran that were destabilizing the region, particularly regarding Iraq
            at the time.”
          </p>
          <p>
            After three years off to earn her PhD at SAIS and do some teaching,
            she returned to the Pentagon from 2012–17 to direct the work of the
            Office of Strategy and Force Development. There she wrestled with
            some of the same questions that occupied Osgood. “What does the
            future security environment look like?” she asks. “What are the
            future wars the U.S. military might have to fight and win? And given
            that, how do you make sure you spend $600-plus billion in a way to
            ensure that it can do so?”
          </p>
          <p>
            The threats are many and varied, and the hardest part of her job,
            she says, was picking winners and losers in the inevitable budget
            skirmishes.
          </p>
          <p>
            In her first book,{' '}
            <em>
              Building Militaries in Fragile States: Challenges for the United
              States
            </em>
            , Karlin studies the decades-old problem of preparing foreign
            militaries to fight insurgencies before they become regional or
            global threats.
          </p>
          <p>
            The book describes four efforts to bolster foreign forces—in Greece,
            Vietnam, and twice in Lebanon—and concludes that the most effective
            approach is to completely overhaul the partner’s fighting force.
            Without these drastic measures, she says, “we need to accept that
            we’re really just going to have a limited impact.”
          </p>
          <p>
            Today, with the White House criticizing longtime allies and
            questioning old alliances, America’s partners are concerned about
            where the United States is heading, Karlin says, adding, “There are
            profound questions that U.S. allies, partners, adversaries, and
            competitors are asking around the world about what the United States
            is willing to do and not do.”
          </p>
          <Core.FullWidthImage
            image={maraKarlinAction}
            alt="Mara Karlin in South Korea"
            caption={
              <>
                Karlin with SAIS students in South Korea, discussing U.S. policy
                options during the first part of the Korean War.{' '}
                <em>Photo provided</em>.
              </>
            }
          />
        </Core.ContentSection>
        <Core.HorizontalRule />
        <Core.ContentSection>
          <Core.Flag>China Studies</Core.Flag>
          <Core.PrimaryHeading>Bridging Two Cultures</Core.PrimaryHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <Core.Blockquote color="beige" author="Mike Lampton">
              Doak was a cultural interpreter who built the intellectual
              infrastructure for the study of Chinese politics and foreign
              policy in the United States.
            </Core.Blockquote>
          }
          marginaliaStackedBottom
        >
          <p>
            <Core.LeadIn color="beige">As a graduate student</Core.LeadIn> at
            Stanford in the late 1960s and early 1970s,{' '}
            <Core.BoldText>David “Mike” Lampton</Core.BoldText> was deeply
            influenced by the work of{' '}
            <Core.BoldText>Arthur Doak Barnett</Core.BoldText>.
          </p>
          <p>
            “Doak was a cultural interpreter who built the intellectual
            infrastructure for the study of Chinese politics and foreign policy
            in the United States,” says Lampton, the George and Sadie Hyman
            Professor of Chinese Studies emeritus and, until recently, the
            director of China Studies at SAIS. “Even if you weren’t one of his
            students, you read all of his books. I took his role to be my model,
            something I aspired to be.”
          </p>
          <p>
            Barnett, who taught at Columbia and conducted research at the
            Brookings Institution before arriving at SAIS in 1982, had been born
            in China to a missionary family. He grew up in Shanghai and—after
            time off to serve as a Marine in World War II and earn his academic
            degrees at Yale— returned to China as a reporter for a Chicago
            newspaper. His reporting took him by oxcart through
            warlord-controlled stretches of western China and to Peiping when
            the People’s Liberation Army arrived in 1949.
          </p>
          <p>
            When the Senate Foreign Relations Committee held hearings in 1966
            about fears China would topple noncommunist governments across Asia,
            the chair invited Barnett to testify. During the historic hearings,
            Barnett urged skeptical lawmakers to engage rather than isolate
            China. Six years later, in 1972, his view prevailed over Cold War
            hard-liners when Richard Nixon arrived in Beijing.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={doakBarnett}
                alt="A. Doak Barnett portrait"
                contentSectionPlacement="marginalia"
              />
              <Core.Caption>
                <Core.LeadIn color="beige">A. Doak Barnett</Core.LeadIn>
                <br />
                Barnett, who taught and conducted research at Brookings before
                arriving at SAIS in 1982, had been born in China to a missionary
                family. He grew up in Shanghai and—after time off to serve as a
                Marine in World War II and earn his academic degrees at
                Yale—returned to China as a reporter for a Chicago newspaper.{' '}
                <em>Photo courtesy of SAIS</em>.
              </Core.Caption>
            </>
          }
          forceIndentation
        >
          <p>
            It was around this time that Lampton moved to the British colony of
            Hong Kong for his research on China’s public health and medical care
            system. Since American scholars were not allowed to visit the
            mainland, Lampton interviewed refugees to get their firsthand
            accounts.
          </p>
          <p>
            At the time, Western experts saw China as tightly controlled by
            officials in Beijing. Based on his research, Lampton concluded that
            the power of the central leaderships was more limited than commonly
            thought. Like top officials everywhere, he says, China’s leaders had
            to cope with recalcitrant subordinates, poor communications, and
            inadequate budgets.
          </p>
          <Core.FullWidthImage
            image={mikeLampton}
            alt="Mike Lampton"
            caption="Mike Lampton"
          />
          <p>
            China was initially constrained, Lampton says, by Mao Zedong’s
            belief that his country would be strong only when it was
            self-sufficient. Evidence of the limits of this strategy was on
            conspicuous display when Lampton first arrived in China in 1976, a
            month after Mao’s death. “At the time, the country was poorer per
            capita than Cambodia,” he says. “There were few cars. The main
            department store sold three colors of Mao suits, thermos bottles,
            and enamel wash basins, and that was about it.”
          </p>
          <p>
            Lampton, the author of a dozen monographs and books on China, joined
            SAIS in 1997 after serving as president of the National Committee on
            U.S.-China Relations in New York (1988–97) and as founding director
            of the China programs at the American Enterprise Institute and what
            is now the Center for National Interest.
          </p>
          <p>
            Today, China has grown “spectacularly,” Lampton says, by integrating
            itself into the global economy and capitalizing on its comparative
            economic advantages, including the scale of its huge domestic
            market, high savings rate, and emphasis on education.
          </p>

          <p>
            Before President Xi Jinping took office, China’s government had been
            loosening state control for four decades, ceding power to
            individuals, corporations, and markets. Now, Lampton says, the
            Chinese Communist Party is “clawing back” that power. The United
            States, meanwhile, has imposed tariffs and accused Beijing of
            backpedaling from reforms and interfering in U.S. politics.
          </p>
          <p>
            He fears today’s tensions could create what he calls a “facsimile”
            of the Cold War, one marked by reduced economic cooperation,
            heightened strategic suspicion, growing military budgets, and
            greater risk of conflict. What’s required now, he says, is something
            akin to Barnett’s quiet advocacy and objective analysis.
          </p>
          <p>
            “While governments have their problems, let’s try to keep our
            productive society-to-society relationships active,” he advises.
          </p>
          <p>
            <Core.BoldText>Andrew Mertha</Core.BoldText>, who joined the SAIS
            faculty in September 2018 as director of China Studies, has written
            three books on China; speaks five languages, including Chinese and
            Khmer; and says he, too, was inspired by the work of Barnett.
            “Doak’s intellectual DNA runs deep through my own work,” says
            Mertha, who is currently the George and Sadie Hyman Professor of
            China Studies.
          </p>
          <Core.FullWidthImage
            image={andrewMertha}
            alt="Andrew Mertha portrait"
            caption="Andrew Mertha"
          />
          <p>
            After graduating from college in 1987, Mertha lived and worked in
            China for seven years before returning to the U.S. to earn his PhD
            from the University of Michigan in 2001.
          </p>
          <p>
            Mertha’s most recent book,{' '}
            <em>Brothers in Arms: Chinese Aid to the Khmer Rouge, 1975–1979</em>
            , describes China’s efforts to support and moderate the radical
            Marxist movement that ruled Cambodia for four violent years. He
            concludes that China’s infusion of aid bought little
            influence—Cambodia was so poorly run because of a reluctance by
            Chinese officials to pressure their hosts, and because
            higher-ranking Chinese officials and institutions didn’t always
            coordinate their work.
          </p>
          <p>
            Mertha says Chinese officials implementing China’s massive Belt and
            Road Initiative—which aims to extend the country’s influence through
            major investments and infrastructure projects in Asia, Europe, and
            beyond—could face similar obstacles in dealing with foreign
            governments.
          </p>
          <p>
            He also says reports of China’s mass incarceration of members of its
            Uighur ethnic minority suspected of religious extremism could affect
            China’s relations with Muslim countries and contribute to homegrown
            terrorism.
          </p>
          <p>
            “In the past, China has emulated some important American successes,
            but it might now be positioning itself to repeat some of our biggest
            failures, such as contributing to the radicalization within the
            Muslim world, this time aimed at China,” he says. “The image of
            China as an inexorably rising power with very little drag on its
            ambitions really needs to be rethought.”
          </p>
          <p>
            Mertha says his work has been heavily influenced by Barnett’s 1967
            book,{' '}
            <em>Cadres, Bureaucracy, and Political Power in Communist China</em>
            , which describes how the national government, a generic government
            ministry, and a village commune work—down to the titles, duties, and
            bureaucratic jargon used.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <div style={{ marginTop: '2em' }} />
              <Core.Blockquote color="beige" author="Andrew Mertha">
                In the past, China has emulated some important American
                successes, but it might now be positioning itself to repeat some
                of our biggest failures ... The image of China as an inexorably
                rising power with very little drag on its ambitions really needs
                to be rethought.
              </Core.Blockquote>
            </>
          }
        >
          <Core.FullWidthImage
            image={andrewMerthaStudents}
            alt="Andrew Mertha portrait"
            caption={
              <>
                Mertha with students in Angkor Thom, Cambodia, in 2015.{' '}
                <em>Photo provided</em>.
              </>
            }
          />
          <p>
            “Wide swaths of that book are still relevant to understanding the
            workings of Chinese institutions today,” Mertha says. “Doak’s work
            provided a window into this black box of authority relations in
            China, which scholars like my colleague Mike Lampton have built
            extensively on.”
          </p>

          <p>
            Today, says Mertha, at a critical time in the U.S-China
            relationship, Chinese officials are having a hard time finding
            officials who can tell them “accurately and credibly what’s going
            on” in the White House. As a result, they are coming to talk with
            American academic and policy experts, including those at SAIS, for
            what they like to call “frank discussions” about U.S.-China
            relations.
          </p>
          <p>
            For Mertha, his position at SAIS gives him the opportunity to play
            the role of U.S.-China cultural interpreter pioneered by Barnett—and
            to help bridge the growing gap between the two cultures. “This is
            the place where I feel like I can do my best work,” he says.
          </p>
        </Core.ContentSection>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    eliotCohen: file(
      relativePath: { regex: $directory }
      name: { eq: "eliot-cohen" }
    ) {
      ...ContentWidthImage
    }
    robertOsgood: file(
      relativePath: { regex: $directory }
      name: { eq: "robert-osgood" }
    ) {
      ...MarginaliaImage
    }
    maraKarlin: file(
      relativePath: { regex: $directory }
      name: { eq: "mara-karlin" }
    ) {
      ...ContentWidthImage
    }
    maraKarlinAction: file(
      relativePath: { regex: $directory }
      name: { eq: "mara-karlin-south-korea" }
    ) {
      ...ContentWidthImage
    }
    doakBarnett: file(
      relativePath: { regex: $directory }
      name: { eq: "doak-barnett" }
    ) {
      ...MarginaliaImage
    }
    mikeLampton: file(
      relativePath: { regex: $directory }
      name: { eq: "mike-lampton" }
    ) {
      ...ContentWidthImage
    }
    andrewMertha: file(
      relativePath: { regex: $directory }
      name: { eq: "andrew-mertha" }
    ) {
      ...ContentWidthImage
    }
    andrewMerthaStudents: file(
      relativePath: { regex: $directory }
      name: { eq: "andrew-mertha-students" }
    ) {
      ...ContentWidthImage
    }
  }
`;

export default FeatureContent;
